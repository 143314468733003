import Swiper from "swiper";
import "swiper/swiper-bundle.css";
// Swiper.use([Autoplay, EffectFade]);

var navToggle = document.getElementById("nav-toggle");
var mainHeader = document.getElementById("main-header");
var nav = document.getElementById("nav");

navToggle.addEventListener(
	"click",
	function () {
		nav.classList.toggle("hidden");
		nav.classList.toggle("bg-white");
		mainHeader.classList.toggle("bg-white");
	},
	false
);

// Related slidehsow
const relatedSlideshow = new Swiper(".swiper-container", {
	slidesPerView: 3,
	spaceBetween: 26,
	allowTouchMove: false,
	breakpoints: {
		1024: {
			spaceBetween: 48,
		},
	},
});

// Open external links in new tab
[...document.links].forEach((link) => {
	if (link.hostname != window.location.hostname) link.target = "_blank";
});

// Video player
var videos = document.querySelector(".video");

var idleTime = 0;

if (videos) {
	[videos].forEach((video) => {
		function timerIncrement() {
			idleTime = idleTime + 1;
			if (idleTime > 2) {
				playButton.classList.add("is-inactive");
			}
		}

		var playButton = video.querySelector(".video__play");
		var fullButton = video.querySelector(".video__full");
		var vid = video.querySelector("video");

		video.addEventListener("mousemove", function () {
			idleTime = 0;
			playButton.classList.remove("is-inactive");
		});

		var idleInterval;

		playButton.addEventListener(
			"click",
			function () {
				if (vid.paused) {
					vid.play();
					playButton.classList.add("is-active", "is-inactive");
					idleInterval = setInterval(timerIncrement, 500);
				} else {
					vid.pause();
					playButton.classList.remove("is-active");
					clearInterval(idleInterval);
				}
			},
			false
		);
		fullButton.addEventListener(
			"click",
			function () {
				vid.requestFullscreen();
			},
			false
		);
		vid.onended = function (e) {
			playButton.classList.remove("is-active");
			playButton.classList.remove("is-inactive");
			clearInterval(idleInterval);
		};
	});
}

// Arrow

var arrowContainer = document.querySelector(".swiper-container");

if (arrowContainer) {
	arrowContainer.addEventListener("mousemove", function (e) {
		var rect = arrowContainer.getBoundingClientRect();
		var x = e.clientX - rect.left;
		var y = e.clientY - rect.top;
		var containerWidth = arrowContainer.clientWidth;

		if (x < containerWidth / 2) {
			arrowContainer.style.cursor = "url('/svg/arrow-left.svg'), pointer";
		} else {
			arrowContainer.style.cursor = "url('/svg/arrow.svg'), pointer";
		}
	});

	arrowContainer.addEventListener("click", function (e) {
		var rect = arrowContainer.getBoundingClientRect();
		var x = e.clientX - rect.left;
		var y = e.clientY - rect.top;
		var containerWidth = arrowContainer.clientWidth;
		if (x < containerWidth / 2) {
			relatedSlideshow.slidePrev();
		} else {
			relatedSlideshow.slideNext();
		}
	});
}

var projectsItem = document.querySelectorAll(".projects-item");

projectsItem.forEach(function (projectsItem) {
	var animatedTitle = projectsItem.querySelector(".animated-title");

	projectsItem.addEventListener("mouseenter", function () {
		animatedTitle.classList.add("top-down");
		setTimeout(function () {
			if (animatedTitle.classList.contains("top-down")) {
				animatedTitle.classList.add("left-right");
			}
		}, 100);
	});

	projectsItem.addEventListener("mouseleave", function () {
		animatedTitle.classList.remove("left-right");
		setTimeout(function () {
			if (!animatedTitle.classList.contains("left-right")) {
				animatedTitle.classList.remove("top-down");
			}
		}, 100);
	});
});
